
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Operator } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});

    const gridOptions: GridOptions = {
      title: "活動規則",
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: "DiscountType",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.MarketRuleDiscountType).find(e => e.Value === cellValue)?.Name : undefined
        },
      ],
      promises: {
        query: model
          ? (params) => {
            if (!params.sortings) params.sortings = [];
            params.sortings.push({ column: "CreatedTime", order: 1 });
            return model.dispatch('marketingRule/query', params)
          }
          : undefined,
        save: model
          ? (params) => model.dispatch('marketingRule/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      // titleWidth: "150",
      // items: [
      //   { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入文字" } } },
      //   { field: "ItemsRange", title: "所有產品件數範圍", span: 12, itemRender: { name: "$select", props: { placeholder: "件數範圍" }, options: model ? Object.values(model.enums.ActivityRange).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
      //   { field: "ItemCount", title: "所有產品件數", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入產品件數" } } },
      //   { field: "RedItemsRange", title: "紅標產品件數範圍", span: 12, itemRender: { name: "$select", props: { placeholder: "件數範圍" }, options: model ? Object.values(model.enums.ActivityRange).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
      //   { field: "RedItemCount", title: "紅標產品件數", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入產品件數" } } },
      //   { field: "GreenItemsRange", title: "綠標產品件數範圍", span: 12, itemRender: { name: "$select", props: { placeholder: "件數範圍" }, options: model ? Object.values(model.enums.ActivityRange).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
      //   { field: "GreenItemCount", title: "綠標產品件數", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入產品件數" } } },
      //   { field: "Discount", title: "折扣", span: 12, itemRender: { name: "$input", props: { type: "float", placeholder: "請輸入折扣 0-1, 例如: 九折則輸入0.1", min: 0, max: 1 } } },
      // ],
      rules: {
        Name: [{ required: true }],
        // ItemsRange: [{ required: true }],
        // ItemCount: [{
        //   required: false,
        //   validator: (params) => {
        //     if (params.data.Type === 1 && !params.itemValue) {
        //       return new Error("必須輸入數量");
        //     }
        //   },
        // }],
        // RedItemsRange: [{ required: true }],
        // RedItemCount: [{
        //   required: false,
        //   validator: (params) => {
        //     if (params.data.Type === 0 && !params.itemValue) {
        //       return new Error("必須輸入數量");
        //     }
        //   },
        // }],
        // GreenItemsRange: [{ required: true }],
        // GreenItemCount: [{
        //   required: false,
        //   validator: (params) => {
        //     if (params.data.Type === 0 && !params.itemValue) {
        //       return new Error("必須輸入數量");
        //     }
        //   },
        // }],
        DisplayDiscount: [{
          required: false,
          validator: (params) => {
            if (params.data.Type !== 1 && !params.itemValue) {
              return new Error();
            }
          },
        }],
        DiscountAmount: [{
          required: false,
          validator: (params) => {
            if (params.data.Type === 1 && !params.data.itemValue) {
              return new Error();
            }
          },
        }],
      }
    };

    return {
      grid,
      gridOptions,
      formOptions
    };
  },
  methods: {
    onGridEdit(row: any, callback: any) {
      if (row.Id) {
        row.DisplayDiscount = 100 - row.Discount * 100;
      } else {
        row.DiscountType = 0;
        row.ItemsRange = 0;
        row.RedItemsRange = 0;
        row.GreenItemsRange = 0;
      }
      callback();
    },
    onFormSubmit(row: any, callback: any) {
      if (row.DiscountType === 1 || row.DiscountType === 4) {
        row.Discount = 0;
      } else {
        row.DiscountAmount = 0;
        row.Discount = (100 - row.DisplayDiscount) / 100;
      }
      callback();
    }
  },
});
