<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-2xl font-bold mr-auto">活動規則</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome
            icon="plus"
            type="fas"
            class="w-4 h-4 mr-1"
          />
          新增活動規則
        </button>
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome
              icon="tasks"
              type="fas"
              class="w-4 h-4 mr-1"
            />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome
                  icon="trash"
                  type="fas"
                  class="w-4 h-4 mr-2"
                />
                刪除
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <vxe-input
              type="search"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              clearable="true"
              @keyup="(e) => {
                if (e.$event.keyCode === 13) grid.refresh();
              }
                "
              @clear="grid.refresh()"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @edit="onGridEdit"
        >
          <template #modal="{ row, submit, reset }">
            <VxeForm
              ref="form"
              :title-width="150"
              title-align="right"
              :rules="formOptions.rules"
              :data="row"
              @reset="reset"
              @submit="onFormSubmit(row, submit)"
            >
              <VxeFormItem
                span="12"
                title="規則類型"
                :item-render="{}"
              >
                <template #default="{ data }">
                  <VxeSelect
                    v-model="data.DiscountType"
                    placeholder="請選擇規則"
                    transfer
                  >
                    <VxeOption
                      v-for="(item, index) in Object.values($model.enums.MarketRuleDiscountType)"
                      :key="'rule-type-' + index"
                      :label="item.Name"
                      :value="item.Value"
                    ></VxeOption>
                  </VxeSelect>
                </template>
              </VxeFormItem>
              <VxeFormItem
                span="12"
                title="名稱"
                filed="Name"
                :item-render="{}"
              >
                <template #default="{ data }">
                  <VxeInput
                    v-model="data.Name"
                    placeholder="請輸入文字"
                  ></VxeInput>
                </template>
              </VxeFormItem>
              <!-- <VxeFormItem span="12" title="所有產品件數範圍" field="ItemsRange" :item-render="{}">
                <template #default="{ data }">
                  <VxeSelect v-model="data.ItemsRange" placeholder="請選擇範圍" :disabled="data.Type === 0">
                    <VxeOption v-for="(item, index) in Object.values($model.enums.ActivityRange)"
                      :key="'items-range-' + index" :label="item.Name" :value="item.Value"></VxeOption>
                  </VxeSelect>
                </template>
              </VxeFormItem> -->
              <!-- <VxeFormItem span="12" title="所有產品件數" field="ItemCount" :item-render="{}">
                <template #default="{ data }">
                  <VxeInput v-model="data.ItemCount" type="number" placeholder="請輸入產品件數" :disabled="data.Type === 0">
                  </VxeInput>
                </template>
              </VxeFormItem> -->
              <!-- <VxeFormItem span="12" title="紅標產品件數範圍" field="RedItemsRange" :item-render="{}">
                <template #default="{ data }">
                  <VxeSelect v-model="data.RedItemsRange" placeholder="請選擇範圍" :disabled="data.Type === 1">
                    <VxeOption v-for="(item, index) in Object.values($model.enums.ActivityRange)"
                      :key="'red-items-range-' + index" :label="item.Name" :value="item.Value"></VxeOption>
                  </VxeSelect>
                </template>
              </VxeFormItem> -->
              <VxeFormItem
                span="12"
                title="紅標產品件數"
                field="RedItemCount"
                :item-render="{}"
              >
                <template #default="{ data }">
                  <VxeInput
                    v-model="data.RedItemCount"
                    type="number"
                    placeholder="請輸入產品件數"
                    :disabled="data.Type === 1"
                  >
                  </VxeInput>
                </template>
              </VxeFormItem>
              <!-- <VxeFormItem span="12" title="綠標產品件數範圍" field="GreenItemsRange" :item-render="{}">
                <template #default="{ data }">
                  <VxeSelect v-model="data.GreenItemsRange" placeholder="請選擇範圍" :disabled="data.Type === 1">
                    <VxeOption v-for="(item, index) in Object.values($model.enums.ActivityRange)"
                      :key="'green-items-range-' + index" :label="item.Name" :value="item.Value"></VxeOption>
                  </VxeSelect>
                </template>
              </VxeFormItem> -->
              <VxeFormItem
                span="12"
                title="綠標產品件數"
                field="GreenItemCount"
                :item-render="{}"
              >
                <template #default="{ data }">
                  <VxeInput
                    v-model="data.GreenItemCount"
                    type="number"
                    placeholder="請輸入產品件數"
                    :disabled="data.Type === 1"
                  >
                  </VxeInput>
                </template>
              </VxeFormItem>
              <VxeFormItem
                span="12"
                title="累積金額"
                :item-render="{}"
              >
                <template #default="{ data }">
                  <VxeInput
                    v-model="data.CumulativeAmount"
                    type="number"
                    placeholder="請輸入累積金額"
                  ></VxeInput>
                </template>
              </VxeFormItem>
              <VxeFormItem
                span="12"
                title="折扣"
                :item-render="{}"
              >
                <template #default="{ data }">
                  <VxeInput
                    v-if="row.DiscountType == 0 || row.DiscountType == 2 || row.DiscountType == 3"
                    type="number"
                    v-model="data.DisplayDiscount"
                    :placeholder="row.DiscountType !== 1 ? '請輸入折數, 例如: 92折則輸入92' : '請輸入金額'"
                    min="1"
                    max="100"
                  />
                  <VxeInput
                    v-else
                    v-model="data.DiscountAmount"
                    type="number"
                    placeholder="請輸入金額"
                  ></VxeInput>
                </template>
              </VxeFormItem>
              <!-- <VxeFormItem v-else span="12" title="折扣金額" field="DiscountAmount"
                :item-render="{}">
              </VxeFormItem> -->
            </VxeForm>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"
            >
            </vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Operator } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});

    const gridOptions: GridOptions = {
      title: "活動規則",
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: "DiscountType",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.MarketRuleDiscountType).find(e => e.Value === cellValue)?.Name : undefined
        },
      ],
      promises: {
        query: model
          ? (params) => {
            if (!params.sortings) params.sortings = [];
            params.sortings.push({ column: "CreatedTime", order: 1 });
            return model.dispatch('marketingRule/query', params)
          }
          : undefined,
        save: model
          ? (params) => model.dispatch('marketingRule/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      // titleWidth: "150",
      // items: [
      //   { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入文字" } } },
      //   { field: "ItemsRange", title: "所有產品件數範圍", span: 12, itemRender: { name: "$select", props: { placeholder: "件數範圍" }, options: model ? Object.values(model.enums.ActivityRange).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
      //   { field: "ItemCount", title: "所有產品件數", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入產品件數" } } },
      //   { field: "RedItemsRange", title: "紅標產品件數範圍", span: 12, itemRender: { name: "$select", props: { placeholder: "件數範圍" }, options: model ? Object.values(model.enums.ActivityRange).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
      //   { field: "RedItemCount", title: "紅標產品件數", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入產品件數" } } },
      //   { field: "GreenItemsRange", title: "綠標產品件數範圍", span: 12, itemRender: { name: "$select", props: { placeholder: "件數範圍" }, options: model ? Object.values(model.enums.ActivityRange).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
      //   { field: "GreenItemCount", title: "綠標產品件數", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入產品件數" } } },
      //   { field: "Discount", title: "折扣", span: 12, itemRender: { name: "$input", props: { type: "float", placeholder: "請輸入折扣 0-1, 例如: 九折則輸入0.1", min: 0, max: 1 } } },
      // ],
      rules: {
        Name: [{ required: true }],
        // ItemsRange: [{ required: true }],
        // ItemCount: [{
        //   required: false,
        //   validator: (params) => {
        //     if (params.data.Type === 1 && !params.itemValue) {
        //       return new Error("必須輸入數量");
        //     }
        //   },
        // }],
        // RedItemsRange: [{ required: true }],
        // RedItemCount: [{
        //   required: false,
        //   validator: (params) => {
        //     if (params.data.Type === 0 && !params.itemValue) {
        //       return new Error("必須輸入數量");
        //     }
        //   },
        // }],
        // GreenItemsRange: [{ required: true }],
        // GreenItemCount: [{
        //   required: false,
        //   validator: (params) => {
        //     if (params.data.Type === 0 && !params.itemValue) {
        //       return new Error("必須輸入數量");
        //     }
        //   },
        // }],
        DisplayDiscount: [{
          required: false,
          validator: (params) => {
            if (params.data.Type !== 1 && !params.itemValue) {
              return new Error();
            }
          },
        }],
        DiscountAmount: [{
          required: false,
          validator: (params) => {
            if (params.data.Type === 1 && !params.data.itemValue) {
              return new Error();
            }
          },
        }],
      }
    };

    return {
      grid,
      gridOptions,
      formOptions
    };
  },
  methods: {
    onGridEdit(row: any, callback: any) {
      if (row.Id) {
        row.DisplayDiscount = 100 - row.Discount * 100;
      } else {
        row.DiscountType = 0;
        row.ItemsRange = 0;
        row.RedItemsRange = 0;
        row.GreenItemsRange = 0;
      }
      callback();
    },
    onFormSubmit(row: any, callback: any) {
      if (row.DiscountType === 1 || row.DiscountType === 4) {
        row.Discount = 0;
      } else {
        row.DiscountAmount = 0;
        row.Discount = (100 - row.DisplayDiscount) / 100;
      }
      callback();
    }
  },
});
</script>
